<template>
  <div class="seller_register">
    <b-overlay :show="submitLoading">
      <!-- CONTACT FIRSTNAME -->
      <app-input
        id="register-seller-contact-firstname"
        v-model="contactFirstName"
        :label="'Contact ' + $t('user.first_name')"
        class="d-lg-flex flex-column"
        required
      />
      <!-- CONTACT LASTNAME -->
      <app-input
        id="register-seller-contact-lastname"
        v-model="contactLastName"
        :label="'Contact ' + $t('user.last_name')"
        required
      />
      <!-- CONTACT EMAIL -->
      <app-input
        id="register-seller-contact-email"
        v-model="contactEmail"
        :label="'Contact ' + $t('user.email')"
        rules="required|email"
      />
      <!-- CONTACT PHONE -->
      <b-form-group :label="'Contact ' + $t('user.phone') + ' *'" label-for="register-seller-contact-phone">
        <validation-provider #default="{ errors }" name="Contact Phone" rules="required" class="validation-required d-lg-flex flex-column">
          <vue-tel-input
            v-model="contactPhone"
            :class="phoneInput && !phoneInput.valid && contactPhone ? 'is-invalid' : null"
            class="form-control"
            :default-country="userLang"
            @validate="val => (phoneInput = val)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          <small v-if="phoneInput && !phoneInput.valid && contactPhone" class="text-danger">{{ $t('validator.invalid_tel.message') }}</small>
        </validation-provider>
      </b-form-group>
      <!-- CONTACT JOBTITLE -->
      <app-input
        id="register-seller-contact-jobtitle"
        v-model="contactJobTitle"
        :label="'Contact ' + $t('user.workrole')"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'CustomerContact',

  components: {
    VueTelInput,
  },
  data() {
    return {
      submitLoading: false,
      phoneInput: null,
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: '',
      contactJobTitle: '',
    }
  },

  computed: {
    ...mapGetters('registration', ['getUserInformations']),

    userLang() {
      return this.$store.state.appConfig.lang
    },
  },

  methods: {
    ...mapActions('registration', [
      'setUserEmergencyContactFirstName',
      'setUserEmergencyContactLastName',
      'setUserEmergencyContactEmail',
      'setUserEmergencyContactPhone',
      'setUserEmergencyContactJobTitle',
      'setRecaptchaToken',
      'register',
    ]),
    async store() {
      this.submitLoading = true
      this.setUserEmergencyContactFirstName(this.contactFirstName)
      this.setUserEmergencyContactLastName(this.contactLastName)
      this.setUserEmergencyContactEmail(this.contactEmail)
      this.setUserEmergencyContactPhone(this.phoneInput.number)
      this.setUserEmergencyContactJobTitle(this.contactJobTitle)

      if (process.env.VUE_APP_RECAPTCHA_SITE_KEY) {
        await this.$recaptchaLoaded()
        this.setRecaptchaToken(await this.$recaptcha('register_client'))
      }
      this.register(this.getUserInformations)
        .then(res => {
          this.$router.push({ name: 'register-validation', params: { id: res.data.id } })
        }).finally(() => {
          this.submitLoading = false
        })
    },
  },
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
