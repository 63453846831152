<template>
  <div>
    <!-- MAIN ACTIVITY -->
    <b-form-group :label="$t('register.main_activity') + ' *'" label-for="register-seller-main-activity">
      <b-overlay :show="typologiesLoading">
        <validation-provider #default="{ errors }" name="Type" rules="required" class="validation-required d-lg-flex flex-column">
          <b-form-radio-group id="organizationTypology" v-model="mainActivity">
            <b-form-radio v-for="typology in typologies" :key="typology.id" :value="typology.id">
              {{ typology.name | trans }}
            </b-form-radio>
          </b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-overlay>
    </b-form-group>
    <!-- OTHER ACTIVITIES -->
    <b-form-group :label="$t('register.other_activities')" label-for="register-seller-other-activity">
      <b-overlay :show="typologiesOptionalsLoading">
        <validation-provider #default="{ errors }" name="Other Activities" class="validation-required d-lg-flex flex-column">
          <b-form-checkbox-group id="organizationTypology" v-model="otherActivities">
            <b-row>
              <b-col v-for="typologyOptional in typologiesOptionals" :key="typologyOptional.id" class="pb-1" cols="12" md="4">
                <b-form-checkbox :value="typologyOptional.id">
                  {{ typologyOptional.name | trans }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-checkbox-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-overlay>
    </b-form-group>
    <!-- COMPANY NAME -->
    <app-input
      id="register-customer-company-name"
      v-model="companyName"
      :label="$t('user.company_name')"
      required
    />
    <!-- TVA -->
    <app-input
      id="register-customer-tva"
      v-model="tva"
      :label="$t('register.tva')"
      rules="required"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  fetchOrganizationConfigurationTypologiesRequest,
} from '@/request/globalApi/requests/organizationConfigurationRequests'

export default {
  name: 'SellerMainInformation',
  data() {
    return {
      typologiesLoading: true,
      typologiesOptionalsLoading: true,
      mainActivity: null,
      typologies: [],
      typologiesOptionals: [],
      otherActivities: [],
      companyName: null,
      structure: null,
      tva: null,
    }
  },

  computed: {
    ...mapState('enum', ['businessStructure', 'businessStructureLoading']),
  },
  mounted() {
    this.fetchEnum2('businessStructure')
    this.APIFetchTypologies()
  },

  methods: {
    ...mapActions('enum', ['fetchEnum2']),

    ...mapActions('registration', [
      'setUserLegalName',
      'setUserBusinessStructure',
      'setUserIntracommunityVatNumber',
      'setUserMainTypologyId',
      'setUserOtherTypologiesIds',
    ]),
    APIFetchTypologies() {
      fetchOrganizationConfigurationTypologiesRequest({ getAllItems: true, userType: 'seller' }).then(response => {
        const { typologies } = response.data
        this.typologiesLoading = false
        this.typologies = typologies
      })
      fetchOrganizationConfigurationTypologiesRequest({ getAllItems: true, userType: 'seller', optionable: true }).then(response => {
        const { typologies } = response.data
        this.typologiesOptionalsLoading = false
        this.typologiesOptionals = typologies
      })
    },
    store() {
      this.setUserLegalName(this.companyName)
      this.setUserBusinessStructure(this.structure) // TODO: useless?
      this.setUserIntracommunityVatNumber(this.tva)
      this.setUserMainTypologyId(this.mainActivity)
      this.setUserOtherTypologiesIds(this.otherActivities)
    },
  },
}
</script>
