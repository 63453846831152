<template>
  <div>
    <!-- FLEET BASES AIRPORTS -->
    <app-select
      id="customerAirports"
      v-model="airportsSelected"
      :options="airports"
      :label-field="$t('register.fleet_bases_airports')"
      multiple
      paginated
      required
      :custom-search="true"
      :per-page="airportsNumberOfItemsPerPage"
      :overlay="airportsLoading"
      :total-items="airportsTotalItems"
      :last-page="airportsLastPage"
      :next-page="airportsNextPage"
      :previous-page="airportsPreviousPage"
      @search="
        search => {
          airportsLoading = true
          searchAirports(search)
        }
      "
      @option:selected="APIFetchAirports"
      @click:previous-page="goPreviousPage"
      @click:next-page="goNextPage"
    />
    <!-- NUMBER OPERATED AIRCRAFTS -->
    <app-input
      id="register-customer-nb-operated-aircrafts"
      v-model.number="aircraftsOperated"
      :label="$t('register.nb_operated_aircrafts')"
      type="number"
      required
    />
    <!-- NUMBER OPERATED AIRCRAFTS -->
    <app-input
      id="register-customer-nb-aoc"
      v-model.number="aoc"
      :label="$t('register.nb_aoc')"
      type="number"
      required
    />
    <!-- COUNTRY -->
    <enum-select
      id="register-customer-aoc-countries"
      v-model="countriesSelected"
      :label="$t('register.aoc_countries')"
      text="text"
      enum-class="CountryCode"
      :reduce="country => country.value"
      multiple
      required
    />
    <!-- COMPANY WEBSITE -->
    <app-input
      id="register-customer-company-website"
      v-model="companyWebsite"
      :label="$t('user.company_website')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { fetchAirportsRequest } from '@/request/globalApi/requests/airportRequests'
import AppSelect from '@/components/AppSelect.vue'

export default {
  name: 'CustomerAirport',

  components: { AppSelect },

  data() {
    return {
      airports: [],
      airportsNameFilter: null,
      airportsNumberOfItemsPerPage: 8,
      airportsPage: 1,
      airportsTotalItems: 0,
      airportsFirstPage: 0,
      airportsLastPage: 0,
      airportsNextPage: 0,
      airportsPreviousPage: 0,
      airportsLoading: true,
      airportsSelected: [],
      countriesSelected: [],
      aircraftsOperated: 0,
      aoc: 0,
      companyWebsite: '',
    }
  },
  mounted() {
    this.APIFetchAirports()
  },

  methods: {
    ...mapActions('registration', [
      'setUserOrganizationAirportsIds',
      'setUserAocCountriesCodes',
      'setUserNumberOfAircrafts',
      'setUserNumberOfAoc',
      'setUserWebSiteUrl',
    ]),
    APIFetchAirports() {
      fetchAirportsRequest({
        numberOfItemsPerPage: this.airportsNumberOfItemsPerPage,
        page: this.airportsPage,
        name: this.airportsNameFilter,
      }).then(response => {
        this.airports = []
        const { airports } = response.data
        airports.forEach(airportData => {
          const airport = airportData
          airport.value = airport.name ? airport.name : airport.id
          airport.label = `${airport.icaoCode} - ${airport.value}`
          this.airports.push(airport)
        })
        this.airportsTotalItems = response.data.totalItems
        this.airportsFirstPage = response.data.firstPage
        this.airportsLastPage = response.data.lastPage
        this.airportsNextPage = response.data.nextPage
        this.airportsPreviousPage = response.data.previousPage
      })
        .finally(() => {
          this.airportsLoading = false
        })
    },
    searchAirports(search) {
      this.airportsNameFilter = search
      this.APIFetchAirports()
    },
    goNextPage() {
      this.airportsPage = this.airportsNextPage
      this.APIFetchAirports()
    },
    goPreviousPage() {
      this.airportsPage = this.airportsPreviousPage
      this.APIFetchAirports()
    },
    store() {
      this.setUserOrganizationAirportsIds(this.airportsSelected.map(airport => airport.id))
      this.setUserAocCountriesCodes(this.countriesSelected)
      this.setUserNumberOfAircrafts(this.aircraftsOperated)
      this.setUserNumberOfAoc(this.aoc)
      this.setUserWebSiteUrl(this.companyWebsite)
    },
  },
}
</script>
