<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" md="6" class="d-none d-lg-flex p-0">
        <SplashScreen />
      </b-col>
      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> {{ $t('navigation.register.title') }} MyFlightConnect </b-card-title>

          <!-- form -->
          <validation-observer #default="{ invalid, validate }" ref="registerValidation">
            <b-form class="auth-login-form mt-2">
              <div v-if="steps.step === 0">
                <RegisterTypologyChoice ref="registerInitComponent" v-model="steps.type" />
              </div>

              <Customer v-if="steps.type === 'buyer'" ref="customerComponent" :steps="steps" />

              <Seller v-else-if="steps.type === 'seller'" ref="sellerComponent" :steps="steps" />

              <!-- submit buttons -->
              <!--              <b-button-->
              <!--                v-if="steps.step > 0"-->
              <!--                type="submit"-->
              <!--                variant="primary"-->
              <!--                block-->
              <!--                @click.prevent="prev()"-->
              <!--              >-->
              <!--                {{ $t('action.previous_step') }}-->
              <!--              </b-button>-->
              <b-button v-if="steps.step < 3" type="submit" block :disabled="invalid" @click.prevent="next()">
                {{ $t('action.next_step') }}
              </b-button>
              <b-button v-else type="submit" block :disabled="invalid" @click.prevent="next()">
                {{ $t('register.send_registration') }}
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-2">
            <div>
              <span>{{ $t('login.already_have_account') }}</span>
              <b-link :to="{ name: 'login' }">
                <span> {{ $t('login.sign_in_instead') }}</span>
              </b-link>
            </div>
            <div>
              <span>{{ $t('login.have_question') }}</span>
              <b-link :to="{ name: 'contact' }">
                <span> {{ $t('login.contact_us') }}</span>
              </b-link>
            </div>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import SplashScreen from '@/views/base/SplashScreen.vue'
import RegisterTypologyChoice from '@/views/base/register/RegisterTypologyChoice.vue'
import Seller from '@/views/base/register/seller/Seller.vue'
import Customer from '@/views/base/register/customer/Customer.vue'

export default {
  name: 'Register',
  provide: {
    registerType: null,
  },
  components: {
    SplashScreen,
    RegisterTypologyChoice,
    Seller,
    Customer,
  },
  data() {
    return {
      typology: null,
      steps: {
        type: null,
        step: 0,
      },
    }
  },

  methods: {
    next() {
      if (this.steps.step === 0) {
        this.$refs.registerInitComponent.registerTypology()
      } else if (this.steps.type === 'buyer' && this.steps.step <= 3) {
        this.$refs.customerComponent.register(this.steps.step)
      } else if (this.steps.type === 'seller' && this.steps.step <= 3) {
        this.$refs.sellerComponent.register(this.steps.step)
      }
      if (this.steps.step < 3) {
        this.steps.step += 1
      }
    },
    // prev() {
    //   if (this.steps.step !== 0) {
    //     this.steps.step -= 1
    //   }
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
