<template>
  <div>
    <!-- ADDRESS -->
    <app-input
      id="register-seller-address"
      v-model="address"
      :label="$t('address.name')"
      required
    />
    <!-- MORE INFORMATIONS -->
    <app-input
      id="register-seller-more-informations"
      v-model="complementaryAddress"
      :label="$t('address.more_informations')"
    />
    <!-- COUNTRY -->
    <enum-select
      id="register-seller-country"
      v-model="country"
      :label="$t('common.country')"
      text="text"
      enum-class="CountryCode"
      :clearable="false"
      required
    />
    <b-row>
      <!-- POSTAL CODE -->
      <b-col cols="6" class="d-flex align-items-center">
        <app-input
          id="register-seller-postal-code"
          v-model="postalCode"
          :label="$t('address.postal_code')"
          required
        />
      </b-col>
      <!-- CITY -->
      <b-col cols="6" class="d-flex align-items-center">
        <app-input
          id="register-seller-city"
          v-model="city"
          :label="$t('address.city')"
          required
        />
      </b-col>
    </b-row>
    <!-- AIRPORTS -->
    <app-select
      id="sellerAirports"
      v-model="servedAirports"
      :options="airports"
      :label-field="$t('register.served_airports')"
      multiple
      paginated
      required
      :per-page="airportsNumberOfItemsPerPage"
      :overlay="airportsLoading"
      :total-items="airportsTotalItems"
      :last-page="airportsLastPage"
      :next-page="airportsNextPage"
      :previous-page="airportsPreviousPage"
      :searching="airportsLoading"
      @search="
        search => {
          airportsLoading = true
          searchAirports(search)
        }
      "
      @option:selected="APIFetchAirports"
      @click:previous-page="goPreviousPage"
      @click:next-page="goNextPage"
    />
    <!-- MORE INFORMATIONS -->
    <app-input
      id="register-seller-address"
      v-model="companyWebsite"
      :label="$t('user.company_website')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { fetchAirportsRequest } from '@/request/globalApi/requests/airportRequests'
import AppSelect from '@/components/AppSelect.vue'

export default {
  name: 'SellerAdditionalInformation',
  components: { AppSelect },

  data() {
    return {
      address: null,
      complementaryAddress: null,
      country: null,
      airportsLoading: true,
      airports: [],
      airportsNameFilter: null,
      airportsNumberOfItemsPerPage: 8,
      airportsPage: 1,
      airportsTotalItems: 0,
      airportsFirstPage: 0,
      airportsLastPage: 0,
      airportsNextPage: 0,
      airportsPreviousPage: 0,
      postalCode: null,
      city: null,
      servedAirports: [],
      companyWebsite: null,
    }
  },
  mounted() {
    this.APIFetchAirports()
  },
  methods: {
    ...mapActions('registration', [
      'setUserAddress',
      'setUserComplementaryAddress',
      'setUserPostalCode',
      'setUserCity',
      'setUserCountryCode',
      'setUserOrganizationAirportsIds',
      'setUserWebSiteUrl',
    ]),

    APIFetchAirports() {
      fetchAirportsRequest({
        numberOfItemsPerPage: this.airportsNumberOfItemsPerPage,
        page: this.airportsPage,
        name: this.airportsNameFilter,
      }).then(response => {
        this.airports = []
        const { airports } = response.data
        airports.forEach(airportData => {
          const airport = airportData
          airport.value = airport.name ? airport.name : airport.id
          airport.label = `${airport.icaoCode} - ${airport.value}`
          this.airports.push(airport)
        })
        this.airportsTotalItems = response.data.totalItems
        this.airportsFirstPage = response.data.firstPage
        this.airportsLastPage = response.data.lastPage
        this.airportsNextPage = response.data.nextPage
        this.airportsPreviousPage = response.data.previousPage
        this.airportsLoading = false
      })
    },
    searchAirports(search) {
      this.airportsPage = 1
      this.airportsNameFilter = search
      this.APIFetchAirports()
    },
    goNextPage() {
      this.airportsPage = this.airportsNextPage
      this.APIFetchAirports()
    },
    goPreviousPage() {
      this.airportsPage = this.airportsPreviousPage
      this.APIFetchAirports()
    },
    store() {
      this.setUserAddress(this.address)
      this.setUserComplementaryAddress(this.complementaryAddress)
      this.setUserPostalCode(this.postalCode)
      this.setUserCity(this.city)
      this.setUserCountryCode(this.country)
      this.setUserOrganizationAirportsIds(this.servedAirports.map(airport => airport.id))
      this.setUserWebSiteUrl(this.companyWebsite)
    },
  },
}
</script>
