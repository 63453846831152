<template>
  <div class="seller_register">
    <b-overlay :show="submitLoading">
      <!-- CONTACT FIRSTNAME -->
      <app-input
        id="register-seller-contact-firstname"
        v-model="contactFirstName"
        :label="'Contact ' + $t('user.first_name')"
        required
      />
      <!-- CONTACT LASTNAME -->
      <app-input
        id="register-seller-contact-lastname"
        v-model="contactLastName"
        :label="'Contact ' + $t('user.last_name')"
        required
      />
      <!-- CONTACT EMAIL -->
      <app-input
        id="register-seller-contact-email"
        v-model="contactEmail"
        :label="'Contact ' + $t('user.email')"
        required
      />
      <!-- CONTACT PHONE -->
      <b-form-group :label="'Contact ' + $t('user.phone') + ' *'" label-for="register-seller-contact-phone">
        <validation-provider #default="{ errors }" name="Contact Phone" rules="required" class="validation-required d-lg-flex flex-column">
          <vue-tel-input
            v-model="contactPhone"
            :class="phoneInput && !phoneInput.valid && contactPhone ? 'is-invalid' : null"
            class="form-control"
            :default-country="userLang"
            @validate="val => (phoneInput = val)"
          />
          <small class="text-danger">{{ errors[0] }}</small>
          <small v-if="phoneInput && !phoneInput.valid && contactPhone" class="text-danger">{{ $t('validator.invalid_tel.message') }}</small>
        </validation-provider>
      </b-form-group>
      <!-- CONTACT JOBTITLE -->
      <app-input
        id="register-seller-contact-jobtitle"
        v-model="contactJobTitle"
        :label="'Contact ' + $t('user.workrole')"
      />

      <!-- SUBSIDIARIES -->
      <b-form-group :label="$t('register.subsidiaries')" label-for="register-seller-subsidiaries">
        <validation-provider #default="{ errors }" name="Subsidiaries" rules="required" class="validation-required d-lg-flex flex-column">
          <b-form-radio v-model="subsidiaries" value="true" name="subsidiaries" selected class="custom-control-primary"> {{ $t('common.yes') }} </b-form-radio>
          <b-form-radio v-model="subsidiaries" value="false" name="subsidiaries" class="custom-control-primary"> {{ $t('common.no') }} </b-form-radio>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- COUNTRY -->
      <enum-select
        id="register-seller-subsidiaries-country"
        v-model="subsidiaryCountry"
        :label="$t('register.subsidiary_country')"
        text="text"
        enum-class="CountryCode"
        multiple
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'SellerContact',

  components: {
    VueTelInput,
  },
  data() {
    return {
      submitLoading: false,
      phoneInput: null,
      subsidiaries: false,
      subsidiaryCountry: [],
      contactFirstName: null,
      contactLastName: null,
      contactEmail: null,
      contactPhone: null,
      contactJobTitle: null,
    }
  },

  computed: {
    ...mapGetters('registration', ['getUserInformations']),

    userLang() {
      return this.$store.state.appConfig.lang
    },
  },

  methods: {
    ...mapActions('registration', [
      'setUserEmergencyContactFirstName',
      'setUserEmergencyContactLastName',
      'setUserEmergencyContactEmail',
      'setUserEmergencyContactPhone',
      'setUserEmergencyContactJobTitle',
      'setUserOtherSubsidiariesInterested',
      'setUserSubsidiariesCountriesCodes',
      'setRecaptchaToken',
      'register',
    ]),

    async store() {
      this.submitLoading = true
      this.setUserEmergencyContactFirstName(this.contactFirstName)
      this.setUserEmergencyContactLastName(this.contactLastName)
      this.setUserEmergencyContactEmail(this.contactEmail)
      this.setUserEmergencyContactPhone(this.phoneInput.number)
      this.setUserEmergencyContactJobTitle(this.contactJobTitle)
      this.setUserOtherSubsidiariesInterested(this.subsidiaries)
      this.setUserSubsidiariesCountriesCodes(this.subsidiaryCountry)

      if (process.env.VUE_APP_RECAPTCHA_SITE_KEY) {
        await this.$recaptchaLoaded()
        this.setRecaptchaToken(await this.$recaptcha('register_seller'))
      }
      this.register(this.getUserInformations)
        .then(res => {
          this.$router.push({ name: 'register-validation', params: { id: res.data.id } })
        }).finally(() => {
          this.submitLoading = false
        })
    },
  },
}
</script>
<style lang="scss">
.seller_register .vti__dropdown-list.below {
  z-index: 2;
}
</style>
