<template>
  <b-form-group :label="$t('register.type')" label-for="register-type" class="w-100 py-2 register_user_type">
    <b-overlay :show="userTypeLoading">
      <validation-provider #default="{ errors }" name="Type" rules="required" class="validation-required">
        <b-form-radio-group id="registerTypeSelected" v-model="registerTypeSelected">
          <div class="typologies_register row justify-content-center mt-2">
            <!-- TODO: key -->
            <b-form-radio v-for="typology in userType" :value="typology.value" class="col-xxl-5 mb-1">
              <div class="title">{{ $t(`register.${typology.value}`) }}</div>
              <div class="separator" />
              <div class="child_typologies">
                <b-overlay :show="sellerTypologiesLoading || buyerTypologiesLoading">
                  <ul v-if="typology.value === 'buyer'">
                    <li v-for="typologyChild in buyerTypologies" :key="typologyChild.id">{{ typologyChild.name |trans }}</li>
                  </ul>
                  <ul v-else-if="typology.value === 'seller'">
                    <li v-for="typologyChild in sellerTypologies" :key="typologyChild.id">{{ typologyChild.name | trans }}</li>
                  </ul>
                </b-overlay>
              </div>
            </b-form-radio>
          </div>
        </b-form-radio-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-overlay>
  </b-form-group>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { fetchOrganizationConfigurationTypologiesRequest } from '@/request/globalApi/requests/organizationConfigurationRequests'

export default {
  name: 'RegisterTypologyChoice',

  // TODO: type
  props: ['registerType'],
  data() {
    return {
      registerTypeSelected: null,
      buyerTypologiesLoading: true,
      sellerTypologiesLoading: true,
      buyerTypologies: [],
      sellerTypologies: [],
    }
  },
  computed: {
    // TODO: enum-select
    ...mapState('enum', ['userType', 'userTypeLoading']),
  },
  watch: {
    registerTypeSelected(val) {
      this.$emit('input', val)
    },
  },
  mounted() {
    this.fetchEnum2('userType')
    this.APIFetchTypologies()
  },

  methods: {
    ...mapActions('enum', ['fetchEnum2']),
    ...mapActions('registration', ['setTypology']),
    registerTypology() {
      this.setTypology(this.registerTypeSelected)
    },
    APIFetchTypologies() {
      fetchOrganizationConfigurationTypologiesRequest({ getAllItems: true, userType: 'seller' }).then(response => {
        const { typologies } = response.data
        this.sellerTypologiesLoading = false
        this.sellerTypologies = typologies
      })
      fetchOrganizationConfigurationTypologiesRequest({ getAllItems: true, userType: 'buyer' }).then(response => {
        const { typologies } = response.data
        this.buyerTypologiesLoading = false
        this.buyerTypologies = typologies
      })
    },
  },
}
</script>

<style lang="scss">
.typologies_register > div {
  border: 1px solid #dcc181;
  padding: 20px;
  border-radius: 0.358rem;
  min-width: 230px;
}
.typologies_register ul {
  padding-left: 10px;
}
.typologies_register .custom-control-label::before {
  left: 80%;
  top: 20px;
}
.typologies_register .custom-control-input {
  left: 80%;
}
.typologies_register .title {
  font-weight: bold;
}
.typologies_register .separator {
  height: 2px;
  width: 100%;
  background-color: #dcc181;
  margin: 10px 0;
}
</style>
