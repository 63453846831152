<template>
  <div>
    <SellerMainInformation v-if="steps.step === 1" ref="sellerMainInformationComponent" />
    <SellerAdditionalInformation v-else-if="steps.step === 2" ref="sellerAdditionalInformationComponent" />
    <SellerContact v-else-if="steps.step === 3" ref="sellerContactComponent" />
    <!-- PRIVACY POLICY -->
    <b-form-group v-if="steps.step === 3" class="pt-3">
      <validation-provider #default="{ errors }" name="Privacy policy & Terms" rules="required" class="validation-required d-lg-flex flex-column">
        <b-form-checkbox v-model="acceptPolicy" value="1" name="policy" class="custom-control-primary">
          {{ $t('register.policy_terms_accept1') }} <b-link :to="{ name: 'privacy-policy' }" target="_blank">{{ lowerCase($t('navigation.privacy_policy')) }}</b-link> & <b-link :to="{ name: 'terms-of-use' }" class="mx-25" target="_blank">{{ lowerCase($t('navigation.terms_of_use')) }}</b-link>
        </b-form-checkbox>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <small v-if="acceptPolicy === false && steps.step === 3" class="text-danger">{{ $t('register.errors.policies') }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { lowerCase } from 'lodash'
import SellerMainInformation from '@/views/base/register/seller/steps/SellerMainInformation.vue'
import SellerAdditionalInformation from '@/views/base/register/seller/steps/SellerAdditionalInformation.vue'
import SellerContact from '@/views/base/register/seller/steps/SellerContact.vue'

export default {
  name: 'Seller',

  components: {
    SellerMainInformation,
    SellerAdditionalInformation,
    SellerContact,
  },
  props: ['steps'],
  data() {
    return {
      acceptPolicy: false,
    }
  },
  computed: {
  },
  methods: {
    lowerCase,
    register() {
      if (this.steps.step === 1) {
        this.$refs.sellerMainInformationComponent.store()
      } else if (this.steps.step === 2) {
        this.$refs.sellerAdditionalInformationComponent.store()
      } else if (this.steps.step === 3 && this.acceptPolicy) {
        this.$refs.sellerContactComponent.store()
      }
    },
  },
}
</script>
