<template>
  <div>
    <CustomerMainInformation v-if="steps.step === 1" ref="customerMainInformationComponent" />
    <CustomerAirport v-else-if="steps.step === 2" ref="customerAirportComponent" />
    <CustomerContact v-else-if="steps.step === 3" ref="customerContactComponent" />
    <!-- PRIVACY POLICY -->
    <b-form-group v-if="steps.step === 3" class="pt-3">
      <validation-provider #default="{ errors }" name="Privacy policy & Terms" rules="required" class="validation-required d-lg-flex flex-column">
        <b-form-checkbox v-model="acceptPolicy" value="1" name="policy" class="custom-control-primary">
          {{ $t('register.policy_terms_accept1') }} <b-link :to="{ name: 'privacy-policy' }" target="_blank">{{ lowerCase($t('navigation.privacy_policy')) }}</b-link> & <b-link :to="{ name: 'terms-of-use' }" class="mx-25" target="_blank">{{ lowerCase($t('navigation.terms_of_use')) }}</b-link>
        </b-form-checkbox>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <small v-if="acceptPolicy === false && steps.step === 3" class="text-danger">{{ $t('register.errors.policies') }}</small>
    </b-form-group>
  </div>
</template>

<script>
import { lowerCase } from 'lodash'

import CustomerMainInformation from '@/views/base/register/customer/steps/CustomerMainInformation.vue'
import CustomerAirport from '@/views/base/register/customer/steps/CustomerAirport.vue'
import CustomerContact from '@/views/base/register/customer/steps/CustomerContact.vue'

export default {
  name: 'Customer',

  components: {
    CustomerMainInformation,
    CustomerAirport,
    CustomerContact,
  },
  props: ['steps'],
  data() {
    return {
      acceptPolicy: false,
    }
  },
  methods: {
    lowerCase,
    register() {
      if (this.steps.step === 1) {
        this.$refs.customerMainInformationComponent.store()
      } else if (this.steps.step === 2) {
        this.$refs.customerAirportComponent.store()
      } else if (this.steps.step === 3 && this.acceptPolicy) {
        this.$refs.customerContactComponent.store()
      }
    },
  },
}
</script>
